<template>
    <div>
        <el-row>
            <el-col :span="24">
                <el-carousel :interval="3000" indicator-position="none" arrow="always">
                    <el-carousel-item v-for="item in picList" :key="item">
                        <img style="width: 100%;" :src="item.img">
                    </el-carousel-item>
                </el-carousel>
            </el-col>
        </el-row>

        <div class="company">
            <!-- 走进清航 -->
            <div class="comeinto">
                <el-row>
                    <el-col :span="16">
                        <div class="left">
                            <h3 style="font-size: 16px;">ABOUT US</h3>
                            <h3>清航空天能源事业部简介</h3>
                            <p>
                                清航空天能源事业部以公司深厚技术积累为基础，是国内领先的先进燃烧技术的商业应用实践者。能源事业部产品涵盖了TH-EaaS清航能源管理平台、GT200燃机到BZ1200自增压燃机等一系列燃烧装置，及工业应用配套的EG201供热燃烧器、EG202电站燃烧器等，以及HOH105氢氧加热器等。TH-EaaS云平台整合了WEB端、APP端以及IOT物联设备，提供了一个多维度、智能化的能源管理与优化环境。TH-EaaS云平台支持多租户的数字能源综合云平台，包括公共云和本地私有化部署。
                            </p>
                            <p>
                                在燃烧技术领域，事业部具有深厚的实力。BZGT600和BZ1200自增压燃机作为集群的明星产品，提供了超高效的燃烧解决方案。EG201供热燃烧器和EG202电站燃烧器、则是工业和电力行业的优选。HOH105氢氧加热器适用于超高温加热环境条件。凭借先进的燃烧技术，显著降低了NOx排放，贡献于清洁能源的推广和应用。TH-QLNYZ1.2、TH-QLNYZ2.0轻量能源站则为分布式能源需求提供了灵活、高效的热能解决方案。                            
                            </p>
                            <p>
                                目前，清航空天能源事业部还在不断拓展产品线，事业部在永磁电机及其能源管理，空压机等产品上也具有相应的解决方案。事业部将继续在能源和动力领域的研发与应用中发挥领军作用，推动产业进步与生态环保的和谐统一。
                            </p>
                            <!-- <button @click="$router.push('/finance')">了解更多</button> -->
                        </div>
                    </el-col>
                    <el-col :span="8">
                        <div class="right">
                            <div class="img2">
                                <img src="../../assets/介绍.png" style="width: 100%;height: 100%;">
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
            <!-- 业务领域 -->
            <div class="business">
                <h3 style="font-size: 30px;color: #000;">业务领域</h3>
                <h3 style="font-weight: normal;">BUSINESS AREAS</h3>
                <div class="swpier">
                    <el-carousel indicator-position="none" :interval="4000" type="card" height="600px">
                        <el-carousel-item v-for="item in businessList" :key="item">
                            <div class="box">
                                <h2>{{ item.title }}</h2>
                                <div class="imgArea">
                                    <img :src="item.img" class="businessImg"/>
                                </div>
                            </div>
                        </el-carousel-item>
                    </el-carousel>
                </div>
            </div>
            <!-- 清航动态 -->
            <div class="active">
                <h3 style="font-size: 30px;color: #000;">近期动态</h3>
                <h3 style="font-weight: normal;">DYNAMIC</h3>
                <div class="body">
                    <!-- <el-row>
                        <el-col :span="6">
                            <router-link to="">
                                <div>
                                    <div class="img"></div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/history">
                                <div>
                                    <div class="img"></div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/culture">
                                <div>
                                    <div class="img"></div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="6">
                            <router-link to="/vision">
                                <div>
                                    <div class="img"></div>
                                </div>
                            </router-link>
                        </el-col>
                    </el-row> -->
                    <!-- <div class="footer">
                        <button @click="$router.push('/fastmsg')">查看更多</button>
                    </div> -->

                    <el-carousel indicator-position="none" :interval="4000">
                        <el-carousel-item v-for="item in dynamicList" :key="item">
                            <el-container>
                                <el-aside width="55%">
                                    <div class="dynamicContent">
                                        <h2 class="title">{{ item.title }}</h2>
                                        <p class="text">{{ item.content }}</p>
                                    </div>

                                </el-aside>
                                <el-main>
                                    <div class="imgArea">
                                        <img :src="item.img" class="dynamicImg" />
                                    </div>
                                </el-main>
                            </el-container>
                        </el-carousel-item>
                    </el-carousel>


                </div>
            </div>
            <!-- 合作客户 -->
            <div class="partner">
                <h3 style="font-size: 30px;color: #000;">合作客户</h3>
                <h3 style="font-weight: normal;">CUSTOMER</h3>
                <div class="body">
                    <div class="content">
                        <ul>
                            <li><img src="../../assets//cooperation/1.png"></li>
                            <li><img src="../../assets//cooperation/2.png"></li>
                            <li><img src="../../assets//cooperation/3.png"></li>
                            <li><img src="../../assets//cooperation/4.png"></li>
                            <li><img src="../../assets//cooperation/5.png"></li>
                            <li><img src="../../assets//cooperation/6.png"></li>
                        </ul>
                    </div>
                    <div class="footer">
                        <button>查看更多</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            picList:[
                {
                    img: require('../../assets/首页1.png')
                }
            ],
            businessList: [
                {
                    title: '新型燃烧器',
                    content: '',
                    img: require('../../assets/business/1.png')
                },
                {
                    title: '先进燃气轮机',
                    content: '',
                    img: require('../../assets/business/2.png')
                },
                {
                    title: '高速电机',
                    content: '',
                    img: require('../../assets/business/3.png')
                },
                {
                    title: '空压机',
                    content: '',
                    img: require('../../assets/business/4.png')
                },
                
            ],
            dynamicList: [
                {
                    title: '喜报 | 上榜2023年首都优秀工程实践案例！',
                    content: '近日，北京市科学技术协会发布《关于2023年首都工程实践案例征集评审结果的公示》，经形式审查与专家评审，清航空天凭借“基于自增压燃烧技术的首钢清洁供热项目”成功入选2023年首都优秀工程实践案例。',
                    img: require('../../assets/dynamic/动态1.png')
                },
                {
                    title: '喜报| 清航空天上榜全国50强',
                    content: '近日，第十二届中国创新创业大赛全国总决赛在成都圆满收官，本届大赛设立全国37个地方赛区，报名企业超过37000家。历时数月，清航空天从北京赛到全国赛半决赛再全国赛总决赛（全国100强），凭借领先的创新技术和综合实力，经过激烈角逐，从37000家企业中脱颖而出，上榜“全国创新创业50强”。',
                    img: require('../../assets/dynamic/动态2.jpg')
                },
                {
                    title: '世界首次，爆震燃机燃烧室1000S+连续试车取得成功！',
                    content: '近日，在EG20爆震燃烧室的阵阵轰鸣声中，清航空天成功完成世界首次，单次试验1000S+连续爆震试车，实验参数稳定、发动机状态稳定。本次试车的圆满成功标志着EG20爆震技术已经成熟，不再处于实验室原型机状态，正式进入工程化阶段。同时EG20部件实验已经满足爆震燃机项目研制需求，可以单独作为氢燃烧器产品进行推广。',
                    img: require('../../assets/dynamic/动态3.png')
                },
                {
                    title: '喜报｜公司获批成为北京市自然科学基金申报依托单位',
                    content: '近日，北京市自然科学基金委员会办公室发布了《关于公布北京市自然科学基金2023年第二批依托单位注册结果的通知》，清航空天顺利通过相关审核，正式获批成为北京市自然科学基金申报依托单位，可以独自申请和承担北京市自然科学基金项目。',
                    img: require('../../assets/dynamic/动态4.png')
                },
            ]
        }
    }
}
</script>
<style lang="scss" scoped>
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}

::v-deep .el-carousel__container {
    height: 500px;
}

.company {
    padding-top: 100px;

    h1 {
        position: relative;
        padding: 10px 0;
        margin-bottom: 100px;
    }

    .img {
        width: 280px;
        height: 300px;
        cursor: pointer;
        display: inline-block;
        background-color: pink;

        &:hover {
            box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
        }
    }
}

.company p {
    padding: 10px 0;
}

.company .comeinto {
    background-color: #f8f8f8;

    .left {
        padding: 50px;

        h3 {
            font-size: 35px;
            color: #003f6a;
            font-weight: normal;
        }

        p {
            font-size: 16px;
            margin: 15px 0;
        }

        button {
            width: 110px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            color: white;
            font-size: 14px;
            background-color: #003f6a;
        }
    }

    .right {
        padding: 50px;
        padding-left: 0;
        display: flex;
        flex-wrap: wrap;

        .img2 {
            margin-top: 30px;
            position: relative;
            width: 450px;
            height: 400px;
            flex-shrink: 0;
            background-color: pink;
        }
    }
}

.company .business {
    height: 700px;
    padding: 30px 0;
    background-color: #f8f8f8;

    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 200px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #ebecee;
    }

    .el-carousel__item:nth-child(2n+1) {
        background-color: #ebecee;
    }

    margin-top: 50px;

    h3 {
        text-align: center;
    }

    .swpier {
        padding: 40px 50px;

        // min-height: 300px;
        .box {
            display: flex;
            flex-direction: column;
            h2 {
                position: relative;
                text-align: center;
                font-size: 25px;
                font-weight: normal;
                padding: 20px 0;

                &::after {
                    content: '';
                    position: absolute;
                    width: 50px;
                    border-bottom: 3px solid #044477;
                    bottom: 5px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
            .imgArea{
                margin-top: 20px;
                height: 520px;
                display: flex;
                flex-direction: row;
                justify-content: center;
            }
        }
    }
}

.company {
    .active {
        margin-top: 50px;
        padding: 30px 0;
        background-color: #f8f8f8;

        h3 {
            text-align: center;
        }

        .body {
            padding: 50px;
            text-align: center;

            .dynamicContent {
                padding: 50px;

                .title {
                    font-size: 26px;
                }

                .text {
                    text-align: left;
                    font-size: 20px;
                    margin-top: 50px;
                }
            }

            .imgArea {
                display: flex;
                justify-content: center;
                height: 450px;

                .dynamicImg {
                    margin-top: 40px;
                    height: 85%;
                }

            }

            .footer {
                text-align: center;

                button {
                    width: 130px;
                    height: 40px;
                    color: #0063ad;
                    line-height: 40px;
                    background-color: #fff;
                    font-size: 14px;
                    border: 1px solid #000;
                    margin-top: 30px;
                    cursor: pointer;

                    &:hover {
                        background-color: #01406b;
                        color: white;
                    }
                }
            }
        }
    }
}

.company {
    .partner {
        margin-top: 50px;
        padding: 30px 0;
        background-color: #f8f8f8;

        h3 {
            text-align: center;
        }

        .body {
            padding: 50px;
            text-align: center;

            .content {

                ul {
                    display: flex;
                    justify-content: space-evenly;
                    flex-wrap: wrap;

                    li {
                        width: 250px;
                        height: 50px;
                        flex-shrink: 0;
                        margin-bottom: 20px;
                        border-radius: 10px;
                        padding: 40px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
            }

            .footer {
                text-align: center;

                button {
                    width: 130px;
                    height: 40px;
                    color: #0063ad;
                    line-height: 40px;
                    background-color: #fff;
                    font-size: 14px;
                    border: 1px solid #000;
                    margin-top: 30px;
                    cursor: pointer;

                    &:hover {
                        background-color: #01406b;
                        color: white;
                    }
                }
            }
        }
    }
}</style>
