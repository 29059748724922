<template>
  <div class="container">
    <div class="banner1">
    </div>
    <!-- 图片下方内容 -->
    <div class="body">
      <ul>
        <li>
          <h3>公关关系主管</h3>
          <div class="content">
            <p>招聘人数：1</p>
            <p>工作地点：重庆市渝北区人力资源产业园北区</p>
            <h1>任职要求：</h1>
            <p>1、本科及以上，形象气质佳，身高1.6米以上，有文案写作，活动主持、总助工作经验优先。</p>
              <p>2、性格开朗，具有良好沟通能力和团队合作精神，执行力强，工作认真负责、细心、可适应短期出差。</p>
          </div>
        </li>
        <li>
          <h3>公关关系主管</h3>
          <div class="content">
              <p>招聘人数：1</p>
              <p>工作地点：重庆市渝北区人力资源产业园北区</p>
              <h1>任职要求：</h1>
              <p>1、本科及以上，形象气质佳，身高1.6米以上，有文案写作，活动主持、总助工作经验优先。</p>
                <p>2、性格开朗，具有良好沟通能力和团队合作精神，执行力强，工作认真负责、细心、可适应短期出差。</p>
            </div>
        </li>
        <li>
          <h3>公关关系主管</h3>
          <div class="content">
              <p>招聘人数：1</p>
              <p>工作地点：重庆市渝北区人力资源产业园北区</p>
              <h1>任职要求：</h1>
              <p>1、本科及以上，形象气质佳，身高1.6米以上，有文案写作，活动主持、总助工作经验优先。</p>
                <p>2、性格开朗，具有良好沟通能力和团队合作精神，执行力强，工作认真负责、细心、可适应短期出差。</p>
            </div>
        </li>
        <li>
          <h3>公关关系主管</h3>
          <div class="content">
              <p>招聘人数：1</p>
              <p>工作地点：重庆市渝北区人力资源产业园北区</p>
              <h1>任职要求：</h1>
              <p>1、本科及以上，形象气质佳，身高1.6米以上，有文案写作，活动主持、总助工作经验优先。</p>
                <p>2、性格开朗，具有良好沟通能力和团队合作精神，执行力强，工作认真负责、细心、可适应短期出差。</p>
            </div>
        </li>
        <li>
          <h3>公关关系主管</h3>
          <div class="content">
              <p>招聘人数：1</p>
              <p>工作地点：重庆市渝北区人力资源产业园北区</p>
              <h1>任职要求：</h1>
              <p>1、本科及以上，形象气质佳，身高1.6米以上，有文案写作，活动主持、总助工作经验优先。</p>
                <p>2、性格开朗，具有良好沟通能力和团队合作精神，执行力强，工作认真负责、细心、可适应短期出差。</p>
            </div>
        </li>
        <li>
          <h3>公关关系主管</h3>
          <div class="content">
              <p>招聘人数：1</p>
              <p>工作地点：重庆市渝北区人力资源产业园北区</p>
              <h1>任职要求：</h1>
              <p>1、本科及以上，形象气质佳，身高1.6米以上，有文案写作，活动主持、总助工作经验优先。</p>
                <p>2、性格开朗，具有良好沟通能力和团队合作精神，执行力强，工作认真负责、细心、可适应短期出差。</p>
            </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
      path: ''
    }
  },
  methods: {
    handleClick () {
      console.log(this.activeName)
    }
  },
  created () {
    this.path = this.$route.path
  },
  watch: {
    path: {
      handler (newValue) {
        window.scrollTo(document.body.scrollHeight, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .banner1 {
    min-height: 500px;
    background-color: pink;
  }

  .body {
    min-height: 500px;
    margin-top: 50px;
    padding: 50px;
    padding-top: 30px;
    background-color: pink;

    ul {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      li {
        width: 300px;
        height: 300px;
        flex-shrink: 0;
        background-color: rgb(255, 255, 255);
        border-radius: 50px 0 50px 0;
        margin-bottom: 20px;
        padding: 40px;

        h3 {
          position: relative;
          font-size: 22px;

          &::after {
            content: '';
            position: absolute;
            bottom: -30px;
            width: 60px;
            left: 0;
            transition: all .5s;
            border-bottom: 3px solid #000;
          }
        }

        .content {
          margin-top: 50px;
          p {
            font-size: 14px;
            line-height: 28px;
          }
          h1 {
            font-size: 14px;
          }
        }
      }

      li:hover h3::after {
        width: 100%;
      }
    }
  }
}</style>
