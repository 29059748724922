<template>
    <div class="foot-menu clearfix">
        <el-row style="color: white;">
            <el-col :span="10">
                <div>
                    <h3>联系我们</h3>
                    <div class="title">
                        <div class="icon">
                            <i class="el-icon-phone"></i>
                        </div>
                        <p>电话： </p>
                    </div>
                    <div style="margin-left: 40px;font-size: 14px;">
                        <p>能源类：13911116412 </p>
                        <p>电机类：15652761202 </p>
                        <p>座 &nbsp;机 &nbsp;：010-50927668 </p>
                    </div>
                    <div class="title">
                        <div class="icon">
                            <i class="el-icon-message"></i>
                        </div>
                        <p>邮箱： business@thu-e.com</p>
                    </div>
                    <div class="title">
                        <div class="icon">
                            <i class="el-icon-location"></i>
                        </div>
                        <p>地址： 北京市大兴区鼎利路10号院19号楼1层101室</p>
                    </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                    <h3>关注我们</h3>
                    <div style="height: 150px;width: 150px;display: flex;margin-top: 30px;">
                        <img src="../../assets/weChat.jpg">
                    </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div>
                    <h3>友情链接</h3>
                    <div>
                        <a href="http://www.thupower.com.cn/" style="color: inherit;">清航空天（北京）科技有限公司</a>
                    </div>
                </div>
            </el-col>
        </el-row>
        <div class="footer" style="margin-left: 480px; margin-top: 50px;">
            <p style="color: white;font-size: 12px;">Copyright 2024 清航空天（北京）科技有限公司</p>
            <p style="color: #757771;margin: 10px 0;margin-left:140px;"></p>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        handleClick(path) {
            this.$router.push(path)
        }
    }
}
</script>
<style scoped lang="scss">
.foot-menu {
    margin: 0 auto;
    position: relative;
    background-color: #333333;
    padding: 30px 50px;
    padding-bottom: 100px;
    font-size: 16px;

    .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .title {
        display: flex;
        flex-direction: row;
        margin-left: 20px;
    }

    h3 {
        padding: 15px 0;
        font-weight: normal;
        font-size: 22px;
        text-align: left;
    }

    p {
        margin: 10px 0;

        // &:hover {
        //     color: rgb(172, 0, 0);
        //     cursor: pointer;
        // }
    }

    .right {
        display: flex;
        justify-content: center;
        padding: 0 50px;

        .right-content {
            width: 200px;
            height: 200px;

            img {
                width: 100%;
                height: 100%;
            }

            p {
                text-align: center;
            }
        }
    }

}
</style>
