<template>
  <div class="app-component">
    <side-menu></side-menu>
    <router-view  v-wechat-title='$route.meta.title'></router-view>
    <foot-menu></foot-menu>
  </div>
</template>

<script>
import SideMenu from './components/common/sideMenu.vue'
import FootMenu from './components/common/footMenu.vue'
export default {
  name: 'app',
  components: {
    SideMenu,
    FootMenu
  }
}
</script>

<style>
@import "styles/app.less";

.app-component {
  height: 100%;
}

/* .view-wrap{
    margin-left: 120px;
    padding-top: 60px;
    background-color: #ededed;
  } */
</style>
