<template>
  <div class="container">
    <div class="banner1">
    </div>
    <!-- 图片下方内容 -->
    <div class="body">
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <!-- 灵活用工 -->
        <el-tab-pane label="集团快讯" name="first">
          <div class="activework">
            <p>
              灵活用工，是指新经济形态下的灵活性用工服务，是中国新经济快速发展下的产物，也是清航大力发展的重要领域。广义上的灵活用工范围很广，除了临时用工以外，还包含:派遣、外包等第三方用工，实习兼职、退休返聘等劳务形式用工，共享经济、平台经济等新型用工形态。
            </p>

            <p>
              清航灵活用工业务形态，具体包含企业在面临人员编制紧张、旺季人才短缺、项目用工短缺、员工短期替补等难题时，将部分具有临时性、短期性、辅助性等可替代的岗位外包出去，由专业机构派驻员工的一种特殊用工形式，尤其是生产类和服务类的一线岗位及某些办公室通用类岗位。
            </p>
            <h3 style="font-size: 24px;">【服务项目】</h3>
            <p>1、短期用工 2、临时用工 3、批量实习 4、兼职服务 5、退休返聘</p>
          </div>
        </el-tab-pane>

        <!-- 人事服务 -->
        <el-tab-pane label="行业咨询" name="second">
          <div class="activework">
            <p>人事服务是指根据客户自身发展需求，将某一项或几项人力资源管理工作或管理职能外包出去，由第三方专业的人力资源外包服务机构进行管理，可有效降低经营成本，提高人才管理效率，实现企业效益最大化。
            </p>

            <p>清航将为客户提供高效实用的“综合性人力资源解决方案”，以专业的服务质量、精益的操作流程、可量化的成本把控，保障客户项目的良性运作。
            </p>
            <h3 style="font-size: 24px;">【服务项目】</h3>
            <p>1.人事代理 2.招聘流程外包 3.人力资源外包 4.人力资源咨询</p>
          </div>
        </el-tab-pane>
        <!-- 服务外包 -->
        <el-tab-pane label="集团公告" name="third">
          <div class="activework">
            <p>服务外包是指以岗位为标的，向客户提供有标准、可复制、非核心的某个或多个岗位人员，并将其相关的所有人事事务完全转移给第三方人力资源服务机构的外包方式。
            </p>

            <p>
              通过服务外包，客户可将岗位招聘、职业化培训、入离职管理、薪酬发放、个税申报、社保缴纳与理赔等各类人事事务工作完全外包给清航，并由清航管理劳动关系、处理劳动纠纷以及承担全部或部分因劳动用工可能带来的法律责任、经济补偿金，让客户享受完全的、深度的人力资源服务。
            </p>
            <h3 style="font-size: 24px;">【服务项目】</h3>
            <p>行政事业单位 生产制造 仓储物流 地产物业 客服售后 零售连锁 餐饮酒店 互联网 医疗健康 新兴经济 ……</p>
          </div>
        </el-tab-pane>
        <!-- 制造外包 -->
        <el-tab-pane label="视频专区" name="forth">
          <div class="activework">
            <p>
              制造外包是指企业根据自身生产制造过程中的实际情况，通过管理创新和流程优化，充分利用企业外部优秀的专业化资源，把内部非核心工段、辅助工序或其他核心业务以外的作业外包给专业的人力资源服务机构的一种商业组织方式。从而提高企业流程自动化的能力。达到降低成本、分散风险、提高效率、提升核心竞争力的目的。
            </p>

            <p>清航具有丰富的蓝领人力储备，可以以项目承揽、产量承接等方式，为制造型企业客户提供专业、定制、一站式的外包服务。
            </p>
            <h3 style="font-size: 24px;">【服务项目】</h3>
            <p>（1）生产制造外包 （2）物流业务外包 （3）后勤业务外包 （4）商业项目外包 （5）辅助工序外包</p>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
      path: ''
    }
  },
  methods: {
    handleClick () {
      console.log(this.activeName)
    }
  },
  created () {
    this.path = this.$route.path
  },
  watch: {
    path: {
      handler (newValue) {
        window.scrollTo(document.body.scrollHeight, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .banner1 {
    min-height: 500px;
    background-color: pink;
  }

  .body {

    ::v-deep .el-tabs__nav {
      margin-left: 350px;
      height: 50px;
    }

    ::v-deep .el-tabs__item {
      height: 50px;
      line-height: 50px;

      &:hover {
        background-color: #003f6a !important;
        color: white !important;
      }
    }

    ::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
      background-color: #003f6a !important;
      color: white !important;
    }

    ::v-deep .el-tabs__content {
      padding: 50px 100px 0 150px;
      padding-bottom: 100px;
    }

    // 清航党建
    .activework {
      p {
        text-indent: 2em;
        margin: 30px 0;
        font-size: 16px;
      }
    }
  }

}</style>
