<template>
  <div class="container">
    <div class="banner1">
      <img src="https://www.jiechenghr.com/data/attachment/202204/19/7798ae80d1f12c4ba861699b2e65590c.jpg" alt="">
    </div>
    <!-- 图片下方内容 -->
    <div class="body">
      <ul>
        <li>
          <h1>清航沈阳分公司</h1>
          <p>地址：辽宁省沈阳市铁西区北一路50号（中国人力资源产业园）</p>
          <p>联系电话：15940353447</p>
        </li>
        <li>
          <h1>清航沈阳分公司</h1>
            <p>地址：辽宁省沈阳市铁西区北一路50号（中国人力资源产业园）</p>
            <p>联系电话：15940353447</p>
        </li>
        <li>
          <h1>清航沈阳分公司</h1>
            <p>地址：辽宁省沈阳市铁西区北一路50号（中国人力资源产业园）</p>
            <p>联系电话：15940353447</p>
        </li>
        <li>
          <h1>清航沈阳分公司</h1>
            <p>地址：辽宁省沈阳市铁西区北一路50号（中国人力资源产业园）</p>
            <p>联系电话：15940353447</p>
        </li>
        <li>
          <h1>清航沈阳分公司</h1>
            <p>地址：辽宁省沈阳市铁西区北一路50号（中国人力资源产业园）</p>
            <p>联系电话：15940353447</p>
        </li>
        <li>
          <h1>清航沈阳分公司</h1>
            <p>地址：辽宁省沈阳市铁西区北一路50号（中国人力资源产业园）</p>
            <p>联系电话：15940353447</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
      path: ''
    }
  },
  methods: {
    handleClick () {
      console.log(this.activeName)
    }
  },
  created () {
    this.path = this.$route.path
  },
  watch: {
    path: {
      handler (newValue) {
        window.scrollTo(document.body.scrollHeight, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .banner1 {
    min-height: 489px;
    background-color: pink;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .body {
    min-height: 500px;
    margin-top: 50px;
    padding: 50px;
    padding-top: 30px;
    background-color: pink;

    ul {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      li {
        width: 300px;
        height: 100px;
        flex-shrink: 0;
        background-color: rgb(255, 255, 255);
        padding: 20px;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
        margin-bottom: 70px;

        h1 {
          font-size: 20px;
          font-weight: normal;
        }

        p {
          line-height: 28px;
        }
      }
    }
  }

}</style>
