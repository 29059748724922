<template>
  <div class="container">
    <div class="banner1">
      <img src="https://www.jiechenghr.com/data/attachment/202204/08/ef583c1512d535a2ccebe3eb1e245814.jpg" alt="">
    </div>
    <!-- 图片下方内容 -->
    <div class="body">
      <ul>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
      path: ''
    }
  },
  methods: {
    handleClick () {
      console.log(this.activeName)
    }
  },
  created () {
    this.path = this.$route.path
  },
  watch: {
    path: {
      handler (newValue) {
        window.scrollTo(document.body.scrollHeight, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .banner1 {
    min-height: 489px;
    background-color: pink;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .body {
    min-height: 500px;
    margin-top: 50px;
    padding: 50px;
    padding-top: 30px;
    background-color: pink;

    ul {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;

      li {
        width: 140px;
        height: 50px;
        flex-shrink: 0;
        background-color: rgb(255, 255, 255);
        margin-bottom: 20px;
        border-radius: 10px;
        padding: 40px;
      }
    }
  }

}
</style>
