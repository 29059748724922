<template>
  <div class="container">
    <div class="banner1">
    </div>
    <!-- 图片下方内容 -->
    <div class="body">
      <el-tabs type="border-card" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="总裁寄语" name="first">
          <!-- 总裁寄语 -->
          <el-row>
            <el-col :span="8">
              <div class="boss-left">
                <h1>总裁寄语</h1>
                <h3>PRESIDENT SPEECH</h3>
                <div class="img"></div>
              </div>
            </el-col>
            <el-col :span="16">
              <div class="boss-right">
                在此，我谨代表集团管理层，对各位同仁过去一年的辛勤努力和付出表示衷心的感谢！
                转变创新经过三年的疫情时代，我们学员的学习习惯和服务需求已经发生了巨大转变，这需要我们各位聚创同仁与时俱进地探索和学习，创新营销模式、咨询话术、教学模式和服务体系。
                学习先进无论面临再大的困难，只要用心想，只要肯动脑，我们依然能克服万难，交出一份漂亮的成绩单。希望各位同仁都能向身边的优秀同伴学习，争取在新的一年，展现新的面貌，再创新的辉煌！
                优秀代表颁奖在学员服务的一线，聚创市场人是当仁不让，冲在最前面的战士。他们积极主动，为学员选择合适的求学路提供帮助，他们实干笃行，为坚守每一块市场疆土锤炼自我。集团副总裁江旭初老师为优秀市场人员颁奖，并对他们“想为、敢为、勤为”的奋斗精神表示欣赏与肯定。
                <label>李嘉诚</label>
              </div>
            </el-col>
          </el-row>
        </el-tab-pane>
        <el-tab-pane label="集团介绍" name="second">
          <!-- 集团介绍 -->
          <div class="jcIntroduce">
            <h1>走进清航</h1>
            <h3>ABOUT US</h3>
            <p> 在此，我谨代表集团管理层，对各位同仁过去一年的辛勤努力和付出表示衷心的感谢！
              转变创新经过三年的疫情时代，我们学员的学习习惯和服务需求已经发生了巨大转变，这需要我们各位聚创同仁与时俱进地探索和学习，创新营销模式、咨询话术、教学模式和服务体系。
              学习先进无论面临再大的困难，</p>
            <el-row>
              <el-col :span="6">
                <div class="count">1000 +</div>
                <div class="content">已服务1000余家人中型企业</div>
              </el-col>
              <el-col :span="6">
                <div class="count">20000 +</div>
                <div class="content">集团外派雇员超过20000人</div>
              </el-col>
              <el-col :span="6">
                <div class="count">500000 +</div>
                <div class="content">累计安置就业员突破500000人</div>
              </el-col>
              <el-col :span="6">
                <div class="count">80000 +</div>
                <div class="content">年均安置就业人员在80000人以上</div>
              </el-col>
            </el-row>
          </div>
        </el-tab-pane>
        <!-- 发展历程 -->
        <el-tab-pane label="发展历程" name="third">
          <div class="development">
            <h1>发展历程</h1>
            <h2>DEVELOPMENT COURSE</h2>
            <el-carousel indicator-position="none" arrow="never" :interval="4000" type="card" height="200px">
              <el-carousel-item>
                <h3 class="medium">
                  <div class="time">2014</div>
                  <el-row>
                    <el-col :span="12">
                      <p>清航集团荣获重庆人力资源产业园品牌之星；

                        清航集团荣获重庆人力资源产业园成长之星；

                        荣获HROOT中国领航者派遣服务优秀企业；

                        清航集团成为第一届人力资源大会高级合作伙伴；

                        清航集团举办十周年荣耀盛典</p>
                    </el-col>
                    <el-col :span="12">
                      <div class="img"></div>
                    </el-col>
                  </el-row>
                </h3>
              </el-carousel-item>
              <el-carousel-item>
                <h3 class="medium">
                  <div class="time">2015</div>
                  <el-row>
                    <el-col :span="12">
                      <p>清航集团荣获重庆人力资源产业园品牌之星；

                        清航集团荣获重庆人力资源产业园成长之星；

                        荣获HROOT中国领航者派遣服务优秀企业；

                        清航集团成为第一届人力资源大会高级合作伙伴；

                        清航集团举办十周年荣耀盛典</p>
                    </el-col>
                    <el-col :span="12">
                      <div class="img"></div>
                    </el-col>
                  </el-row>
                </h3>
              </el-carousel-item>
              <el-carousel-item>
                <h3 class="medium">
                  <div class="time">2016</div>
                  <el-row>
                    <el-col :span="12">
                      <p>清航集团荣获重庆人力资源产业园品牌之星；

                        清航集团荣获重庆人力资源产业园成长之星；

                        荣获HROOT中国领航者派遣服务优秀企业；

                        清航集团成为第一届人力资源大会高级合作伙伴；

                        清航集团举办十周年荣耀盛典</p>
                    </el-col>
                    <el-col :span="12">
                      <div class="img"></div>
                    </el-col>
                  </el-row>
                </h3>
              </el-carousel-item>
              <el-carousel-item>
                <h3 class="medium">
                  <div class="time">2017</div>
                  <el-row>
                    <el-col :span="12">
                      <p>清航集团荣获重庆人力资源产业园品牌之星；

                        清航集团荣获重庆人力资源产业园成长之星；

                        荣获HROOT中国领航者派遣服务优秀企业；

                        清航集团成为第一届人力资源大会高级合作伙伴；

                        清航集团举办十周年荣耀盛典</p>
                    </el-col>
                    <el-col :span="12">
                      <div class="img"></div>
                    </el-col>
                  </el-row>
                </h3>
              </el-carousel-item>
              <el-carousel-item>
                <h3 class="medium">
                  <div class="time">2018</div>
                  <el-row>
                    <el-col :span="12">
                      <p>清航集团荣获重庆人力资源产业园品牌之星；

                        清航集团荣获重庆人力资源产业园成长之星；

                        荣获HROOT中国领航者派遣服务优秀企业；

                        清航集团成为第一届人力资源大会高级合作伙伴；

                        清航集团举办十周年荣耀盛典</p>
                    </el-col>
                    <el-col :span="12">
                      <div class="img"></div>
                    </el-col>
                  </el-row>
                </h3>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业文化" name="fourth">
          <!-- 企业文化 -->
          <div class="culture">
            <div class="banner2">
              <el-row>
                <el-col :span="12">
                  <div class="left">
                    <h1>企业文化</h1>
                    <h2 style="font-weight: normal;margin-bottom: 20px;">ENTERPRISE CULTURE</h2>
                    <div class="lilian">
                      <div class="module1">
                        <h3>我们的理念</h3>
                        <p>管理理念</p>
                        <p>-----识人以明 用人以善 爱人以德</p>
                        <p>管理理念</p>
                        <p>-----以客户为中心 爱员工如家人 视公益为己任</p>
                      </div>

                      <div class="module2" style="margin-top: 50px;">
                        <h3>我们的追求</h3>
                        <p>为员工营造温馨的平台</p>
                        <p>-----享受工作 让工作改变生活</p>
                        <p>为客户提供卓越的服务</p>
                        <p>-----追求高端品质,注重产品创新</p>
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="right">
                    <h1>清航合文化</h1>
                    <div class="module">
                      <div class="img"></div>
                      <h3>配合</h3>
                      <p>在业务开展过程中，强调内部人员的相互扶持，外部人员相互扶持，达到精诚团结。</p>
                    </div>
                    <div class="module">
                      <div class="img"></div>
                      <h3>整合</h3>
                      <p>在业务开展过程中，强调内部人员的相互扶持，外部人员相互扶持，达到精诚团结。</p>
                    </div>
                    <div class="module">
                      <div class="img"></div>
                      <h3>符合</h3>
                      <p>在业务开展过程中，强调内部人员的相互扶持，外部人员相互扶持，达到精诚团结。</p>
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="集团利益" name="fifth">集团利益</el-tab-pane>
        <!-- 清航党建 -->
        <el-tab-pane label="清航党建" name="sixth">
          <div class="jcdangjian">
            <p>党建工作对企业的发展意义重大，为企业可持续发展，保驾护航，确保发展方向正确并提供組织保障，“听党的话，跟党走”是企业党建的基本原则。</p>

            <p>清航集团高度重视党建工作，于2018年成立集团党支部。集团积极组织学习中国共产党党史和党的理论思想体系、 党的大政方针政策、
              党员的职责义务，用党的先进性和党员的先锋模范作用，积极影响集团文化和团队建设，积极带动集团健康发展。</p>

            <p>
              通过党建工作，有效提高集团员工的思想觉悟，端正积极的工作态度，从而认真踏实工作，提高企业的工作效率；通过党建工作，弘扬积极向上的企业文化，让员工浸染和树立正确的思想和价值观，从而增强自己的道德标准和文化素养，为建立良好的企业文化氛围奠定基础；通过党建工作，培育集团员工的主人翁意识，加强员工的使命感，为实现集团发展壮大而共同奋斗。
            </p>

            <p>
              党支部成立以后，在不忘传统思想政治教育的同时，从实际出发，结合公司性质以及职能，力求将党建工作紧紧国绕看集团的发展战略，达到与公集团发展“同频共振”的最佳状态。组织参观了“涪陵816地下核遗址”红色之旅等活动，充分提升了党员们的思想觉悟和工作积极性。
            </p>
          </div>
        </el-tab-pane>
        <el-tab-pane label="集团荣誉" name="seventh">
          <div class="honor">
            <h1>集团荣誉</h1>
            <h2>GROUP OF HONOR</h2>
            <el-carousel indicator-position="none" arrow="never" :interval="4000" type="card" height="200px">
              <el-carousel-item>
                <div class="img"></div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="img"></div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="img"></div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="img"></div>
              </el-carousel-item>
              <el-carousel-item>
                <div class="img"></div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      activeName: 'first',
      path: ''
    }
  },
  methods: {
    handleClick () {
      console.log(this.activeName)
    }
  },
  created () {
    this.path = this.$route.path
  },
  watch: {
    path: {
      handler (newValue) {
        window.scrollTo(document.body.scrollHeight, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  .banner1 {
    min-height: 500px;
    background-color: pink;
  }

  .body {
    min-height: 500px;

    ::v-deep .el-tabs__nav {
      margin-left: 350px;
    }
    ::v-deep .el-tabs__item {
            height: 50px;
            line-height: 50px;
            &:hover {
                background-color: #003f6a !important;
                color: white !important;
            }
        }

        ::v-deep .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
            background-color: #003f6a !important;
            color: white !important;
        }

    ::v-deep .el-tabs__content {
      padding: 50px 100px 0 150px;
      padding-bottom: 100px;
    }

    .boss-left {
      position: relative;
      background-color: #eee;
      width: 100%;
      height: 800px;

      .img {
        position: absolute;
        width: 1230px;
        height: 300px;
        background-color: pink;
        left: 0;
        bottom: 0;
      }

      h1 {
        position: absolute;
        left: 30px;
        top: 100px;
        color: #000;
        font-size: 40px;
        font-weight: normal;
      }

      h3 {
        position: absolute;
        left: 30px;
        top: 180px;
        color: #8c8c8c;
        font-size: 30px;
        font-weight: normal;
      }
    }

    .boss-right {
      position: relative;
      text-indent: 2em;
      padding-left: 100px;
      font-size: 18px;
      line-height: 36px;

      label {
        position: absolute;
        bottom: -80px;
        right: 200px;
      }
    }

    // 清航介绍
    .jcIntroduce {
      h1 {
        text-align: center;
        margin-bottom: 10px;
      }

      h3 {
        font-weight: normal;
        text-align: center;
        color: #8c8c8c;
        margin-bottom: 20px;
      }

      p {
        font-size: 18px;
        line-height: 2em;
        margin-bottom: 40px;
      }

      .count {
        font-size: 40px;
        font-weight: bold;
        color: #003f6a;
        margin-bottom: 20px;
      }
    }

    // 发展历程
    .development {
      h1 {
        text-align: center;
        margin-bottom: 10px;
      }

      h2 {
        font-weight: normal;
        text-align: center;
        color: #8c8c8c;
        margin-bottom: 80px;
      }

      .el-carousel {
        height: 260px;
      }

      .el-carousel__item {
        color: #475669;
        font-size: 14px;
        padding: 30px;
        background-color: #f0f0f0;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

        .time {
          font-size: 40px;
          font-weight: normal;
        }

        p {
          font-weight: normal;
          font-size: 16px;
          padding-right: 10px;
        }

        .img {
          width: 100%;
          height: 148px;
          background-color: pink;
        }
      }
    }

    // 企业文化
    .culture {
      .banner2 {
        min-height: 750px;
        background: url('@/assets/banner2.jpeg') no-repeat center;
        background-size: contain;

        .left {
          min-height: 600px;
          color: white;
          padding: 50px 50px 100px 50px;
          background-color: #00000055;

          h1 {
            font-size: 32px;
          }

          h2 {
            font-size: 18px;
          }

          .lilian {
            height: 494px;
            border: 2px solid white;
            padding-left: 20px;
            padding-top: 20px;

            h3 {
              font-size: 28px;
              font-weight: normal;
              margin-bottom: 15px;
            }

            p {
              font-size: 16px;
              height: 30px;
              line-height: 30px;
            }
          }
        }

        .right {
          height: 600px;
          color: white;
          padding: 50px 50px 100px 50px;

          h1 {
            font-size: 32px;
          }

          .module {
            margin: 60px 0 30px 0;

            .img {
              width: 62px;
              height: 62px;
              background-color: pink;
            }

            h3 {
              font-size: 24px;
              color: #000;
              margin: 10px 0;
            }

            p {
              color: #333;
            }
          }

        }
      }
    }

    // 清航党建
    .jcdangjian {
      p {
        text-indent: 2em;
        margin: 30px 0;
        font-size: 16px;
      }
    }

    // 清航荣誉
    .honor {
      h1 {
        text-align: center;
        font-size: 32px;
      }
      h2 {
        font-size: 18px;
        font-weight: normal;
        color: #b9b9b9;
        text-align: center;
        margin-top: 10px;
        margin-bottom: 50px;
      }
      .el-carousel {
        height: 260px;
      }

      .el-carousel__item {
        color: #475669;
        font-size: 14px;
        padding: 30px;
        background-color: red;
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

        .img {
          padding: 10px 30px;
          background-color: pink;
          height: 200px;
        }
      }
    }
  }

}
</style>
