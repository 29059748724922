import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import store from './store'
import VueWechatTitle from 'vue-wechat-title' 


import '../node_modules/element-ui/lib/theme-chalk/index.css'
Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(VueWechatTitle)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
