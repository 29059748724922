import Vue from 'vue'
// import Resource from 'vue-resource'
import NProgress from 'nprogress'
import Router from 'vue-router'

import index from '@/components/introduction/index-view.vue'
// 生意模块路由
import BusinessFinance from '@/components/business/finance-view.vue'
// 服务外包
import PartnersView from '@/components/cooperation/partners-view.vue'
// 合作客户
import BranchJinView from '@/components/connection/branch-jin.vue'
// 清航动态
import FastmsgView from '@/components/active/FastmsgView.vue'
// 加入我们
import JoinUs from '@/components/join/JoinUs.vue'
// 分支机构
import BranchView from '@/components/branch/BranchView.vue'

Vue.use(Router)
const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      title: '公司简介',
      component: index,
      meta:{
        title:"清航空天能源事业部"
      }
    },
    {
      path: '/finance',
      title: '融资租赁',
      component: BusinessFinance
    },
    {
      path: '/partners',
      title: '合作机构',
      component: PartnersView
    },
    {
      path: '/branch-jin',
      title: '金茂大厦总部',
      component: BranchJinView
    },
    // 清航动态路由
    {
      path: '/fastmsg',
      title: '清航动态',
      component: FastmsgView
    },
    // 加入我们
    {
      path: '/joinus',
      title: '加入我们',
      component: JoinUs
    },
    // 分支机构
    {
      path: '/branch',
      title: '分支机构',
      component: BranchView
    }
  ]
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  // const VueRouterPush = Router.prototype.push
  // Router.prototype.push = function (to) {
  //   return VueRouterPush.call(this, to).catch(err => err)
  // }
  next()
})

router.afterEach((transition) => {
  NProgress.done()
})

export default router
